import React from "react";
import styled from "styled-components";

const AboutHampi = () => {
  return (
    <MainContainer>
      <div className="container d-flex gap-4 p-5">
        <div className="left">
          <h3>About Hampi</h3>
          <img
            src="https://media.istockphoto.com/id/1270774245/photo/hampi-stone-chariot-the-antique-stone-art-piece-from-unique-angle-with-amazing-blue-sk.jpg?s=612x612&w=0&k=20&c=LugJgTiUd9y_8MO2KDQ2I4IvOD_7BH5x_R8iRApqEUg="
            alt="Hampi"
            loading="lazy"
          />
        </div>
        <div className="right mt-4">
          <p>
            Hampi or Hampe, also referred to as the Group of Monuments at Hampi,
            is a UNESCO World Heritage Site located in Hampi town, Vijayanagara
            district, east-central Karnataka, India. Hampi was the capital of
            the Vijayanagara Empire in the 14th century. It is a fortified city.
            Chronicles left by Persian and European travellers, particularly the
            Portuguese, say that Hampi was a prosperous, wealthy and grand city
            near the Tungabhadra River, with numerous temples, farms and trading
            markets. By 1500 CE, Hampi-Vijayanagara was the world's
            second-largest medieval-era city after Beijing, and probably India's
            richest at that time, attracting traders from Persia and Portugal.
            The Vijayanagara Empire was defeated by a coalition of Muslim
            sultanates; its capital was conquered, pillaged and destroyed by
            sultanate armies in 1565, after which Hampi remained in ruins.
          </p>
          <p>
            Located in Karnataka near the modern-era city of Hosapete, Hampi's
            ruins are spread over 4,100 hectares (16 sq mi) and it has been
            described by UNESCO as an "austere, grandiose site" of more than
            1,600 surviving remains of the last great Hindu kingdom in South
            India that includes "forts, riverside features, royal and sacred
            complexes, temples, shrines, pillared halls, mandapas, memorial
            structures, water structures and others"
          </p>
        </div>
      </div>
    </MainContainer>
  );
};
export default AboutHampi;
const MainContainer = styled.div`
  /* background-image: linear-gradient(to right, white, var(--blue)); */
  background: rgb(13, 110, 253);
  background: linear-gradient(
    90deg,
    rgba(13, 110, 253, 1) 0%,
    rgba(0, 142, 255, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  .left {
    h3 {
      padding-left: 30px;
      color:white;
      @media only screen and (max-width: 600px) {
        padding: 0;
      }
    }
    img {
      border-radius: 60px 0px 0px 60px;

      @media only screen and (max-width: 600px) {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
  .right {
    color: white;
    @media only screen and (max-width: 600px) {
      color: black;
    }
  }
  .container {
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
`;
