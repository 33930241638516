import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import PublicRoutesLayout from "./PublicRoutesLayout";

import { Route } from "react-router-dom";
import {
  Home,
  ViewPackageDetails,
  EnquireNow,
  BusPackages,
  CityGuide,
  TandC,
  CancelPolicy,
  Privacy,
  Disclaimer,
  CarRental,
  TourPackages,
  About,
  PageNotFound,
  Explore,
} from "../pages";
import Hotels from "../pages/Hotels/Hotels";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route>
        {/* Public Routes */}
        <Route path="/" element={<PublicRoutesLayout />}>
          {/* Navbar Pages */}
          <Route path="/" element={<Home />} />
          <Route path="/tour-packages" element={<TourPackages />} />
          <Route path="/tour-packages/:slug" element={<ViewPackageDetails />} />
          <Route path="/bus-packages" element={<BusPackages />} />
          <Route path="/bus-packages/:slug" element={<ViewPackageDetails />} />
          <Route path="/hotels" element={<Hotels />} />
          <Route path="/car-rental" element={<CarRental />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/enquiry-now" element={<EnquireNow />} />

          {/*Footer Pages*/}
          <Route path="/disclaimer-policy" element={<Disclaimer />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route
            path="/cancellation-refund-policy"
            element={<CancelPolicy />}
          />
          <Route path="/terms-conditions" element={<TandC />} />
          <Route path="/city-guide" element={<CityGuide />} />

          {/*TopFooter-Explore*/}
          <Route path="/explore/:slug" element={<Explore />} />

          {/* Page Not Found */}
          {/* Login Page */}
        </Route>
        <Route path="*" element={<PageNotFound />} />

      </Route>
    </>
  )
);
